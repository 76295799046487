
















































































































































































import store, { useUserGetters, useUserActions } from '@/store';
import { ActionTypes } from '@/store/modules/tools/actions';
import { computed, defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'MRewardsAndCredentialsManagement',
  props: {
    programId: {
      type: String,
      required: true
    },
    fetchProgram: {
      type: Function,
      default: () => {}
    },
    isTemplate: {
      type: Boolean,
      default: false,
      required: false
    },
    isDraft: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  setup(props: any, ctx: any) {
    const { getProgramDoc } = useUserGetters(['getProgramDoc']);
    const { saveRewardsAndCredentials } = useUserActions(['saveRewardsAndCredentials']);
    const programDoc = computed({
      get: () => getProgramDoc.value,
      set: newVal => {
        ctx.emit('update:value', newVal);
      }
    });
    const isLoading = ref();
    const CredentialListItems = ref(['Internship', 'Externship', 'Sponsor']);
    const activities = ref();
    const credentialsArr = ref([
      {
        id: 1,
        credentials: [],
        activities: []
      }
    ]);
    const rewardsArr = ref([
      {
        id: 1,
        rewards: [],
        activities: []
      }
    ]);

    let credentialId = 2;
    const addCredential = () => {
      credentialsArr.value.push({
        id: credentialId++,
        credentials: [],
        activities: []
      });
    };
    const removeCredential = id => {
      const index = credentialsArr.value.findIndex(element => element.id === id);

      if (index > -1) {
        credentialsArr.value.splice(index, 1);
      }
    };

    const isLastItem = item => {
      return credentialsArr.value.indexOf(item) === credentialsArr.value.length - 1;
    };

    let rewardsId = 2;
    const addRewards = () => {
      rewardsArr.value.push({
        id: rewardsId++,
        rewards: [],
        activities: []
      });
    };
    const removeRewards = id => {
      const index = rewardsArr.value.findIndex(element => element.id === id);

      if (index > -1) {
        rewardsArr.value.splice(index, 1);
      }
    };

    const isLastItemReward = item => {
      return rewardsArr.value.indexOf(item) === rewardsArr.value.length - 1;
    };
    const getDocData = async () => {
      const document = getProgramDoc.value;
      if (document?.credentials && typeof document?.credentials === 'object')
        credentialsArr.value = document?.credentials;
      if (document?.rewards && typeof document?.rewards === 'object')
        rewardsArr.value = document?.rewards;
      const getAdksNames = document?.adks?.map(data => {
        return data.name;
      });
      activities.value = getAdksNames;
    };

    getDocData();

    function removeDuplicateObjects(arr) {
      const uniqueCombinations = new Set();
      const uniqueObjects = arr.filter(obj => {
        const combination = JSON.stringify({
          credentials: obj.credentials,
          activities: obj.activities
        });
        if (!uniqueCombinations.has(combination)) {
          uniqueCombinations.add(combination);
          return true;
        }
        return false;
      });

      return uniqueObjects;
    }

    const save = async () => {
      isLoading.value = true;
      let document_type = props?.isTemplate ? 'ProgramSchema' : 'Program';
      if (props?.isDraft) {
        document_type = 'ProgramRevision';
      }
      const filteredCredentialsArr = removeDuplicateObjects(credentialsArr?.value);
      const filteredRewardsArr = removeDuplicateObjects(rewardsArr?.value);
      const payload = {
        document_id: props?.programId,
        document_type,
        credentials: filteredCredentialsArr,
        rewards: filteredRewardsArr
      };
      console.log(payload);
      const response = await saveRewardsAndCredentials(payload);
      if (response) {
        props.fetchProgram();
        setTimeout(() => {
          isLoading.value = false;
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: response?.message,
            type: 'success',
            isShowSnackbar: true
          });
        }, 2000);
      }
    };

    const hasValidationErrors = computed(() => {
      let flag = true;
      credentialsArr?.value?.forEach(credential => {
        if (credential?.credentials?.length >= 1 && credential?.activities?.length >= 1) {
          flag = false;
        }
      });
      rewardsArr?.value?.forEach(credential => {
        if (credential?.rewards?.length >= 1 && credential?.activities?.length >= 1) {
          flag = false;
        }
      });
      return flag;
    });

    return {
      save,
      isLoading,
      programDoc,
      credentialsArr,
      CredentialListItems,
      addCredential,
      removeCredential,
      isLastItem,
      rewardsArr,
      removeRewards,
      isLastItemReward,
      addRewards,
      activities,
      hasValidationErrors
    };
  }
});
